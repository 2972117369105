<template>
    <div class="textStyle">
        <div class="title">字体设置</div>
        <div class="item flex-box">
            <el-select v-model="font" placeholder="请选择" size="small">
                <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <el-color-picker class="ml10" v-model="color" size="small"></el-color-picker>
        </div>
        <div class="item flex-box">
            <el-input-number v-model="fontSize" controls-position="right" size="small"></el-input-number>
            <div class="font-weight-box ml10">
                <div class="font-weight-item flex1 font-weight-column2">
                    <img src="@/assets/images/creation/creationMng_(64).png" alt="">
                    <!-- <img src="@/assets/images/creation/creationMng_(5).png" alt=""> -->
                </div>
                <div class="font-weight-item flex1 font-weight-column2">
                    <img src="@/assets/images/creation/creationMng_(4).png" alt="">
                    <!-- <img src="@/assets/images/creation/creationMng_(70).png" alt=""> -->
                </div>
            </div>
        </div>
        <div class="item">
            <div class="font-weight-box">
                <div class="font-weight-item font-weight-column3">
                    <img src="@/assets/images/creation/creationMng_(65).png" alt="">
                    <!-- <img src="@/assets/images/creation/creationMng_(6).png" alt=""> -->
                </div>
                <div class="font-weight-item font-weight-column3">
                    <img src="@/assets/images/creation/creationMng_(8).png" alt="">
                    <!-- <img src="@/assets/images/creation/creationMng_(67).png" alt=""> -->
                </div>
                <div class="font-weight-item font-weight-column3">
                    <img src="@/assets/images/creation/creationMng_(7).png" alt="">
                    <!-- <img src="@/assets/images/creation/creationMng_(66).png" alt=""> -->
                </div>
            </div>
        </div>
        <div class="item">
            <div class="text-wrap">
                <img src="@/assets/images/creation/creationMng_(9).png" alt="">
                <div class="txt">字间距</div>
                <div class="slider-box">
                    <el-slider size="mini" v-model="wordSpace" :step="1" :min="0" :max="300"
                        :show-tooltip="false"></el-slider>
                </div>
                <div class="txt">{{ wordSpace }}px</div>
            </div>
        </div>
        <div class="item">
            <div class="text-wrap">
                <img src="@/assets/images/creation/creationMng_(10).png" alt="">
                <div class="txt">行间距</div>
                <div class="slider-box">
                    <el-slider size="mini" v-model="lineHeight" :step="0.1" :min="1" :max="4"
                        :show-tooltip="false"></el-slider>
                </div>
                <div class="txt">{{ lineHeight }}倍</div>
            </div>
        </div>
        
        <text-preset></text-preset>

        <div class="title mt20">位置及尺寸</div>
        <div class="item flex-box">
            <el-input class="inp94" placeholder="请输入" size="small" v-model="locationX">
                <template slot="append">X</template>
            </el-input>
            <el-input class="inp94 ml10" placeholder="请输入" size="small" v-model="locationY">
                <template slot="append">Y</template>
            </el-input>
        </div>
        <div class="item flex-box">
            <el-input class="inp94" placeholder="请输入" size="small" v-model="width">
                <template slot="append">W</template>
            </el-input>
            <el-input class="inp94 ml10" placeholder="请输入" size="small" v-model="height">
                <template slot="append">H</template>
            </el-input>
        </div>
        <div class="item flex-box">
            <el-input class="inp94" placeholder="请输入" size="small" v-model="trangle">
                <template slot="append">
                    <img src="@/assets/images/creation/creationMng_(63).png" alt="">
                </template>
            </el-input>
        </div>

    </div>
</template>
<script>
import TextPreset from './preset.vue'

export default {
    components: { TextPreset },
    data() {
        return {
            options1: [],
            font: 0,
            color: '',
            fontSize: 0,
            wordSpace: 0,
            lineHeight: 0.1,
            locationX: 0,
            locationY: 0,
            width: 0,
            height: 0,
            trangle: 0,
        }
    }
}
</script>
<style lang="scss" scoped>
.textStyle {
    .title {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
    }

    .item {
        margin-top: 16px;

        .font-weight-box {
            height: 32px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #D1D1D1;
            display: flex;
            padding: 8px 0;

            .font-weight-item {
                border-right: 1px solid #E4E4E4;

                img {
                    cursor: pointer;
                    width: 16px;
                    height: 16px;
                }

                &:nth-last-child(1) {
                    border-right: none;
                }
            }

            .font-weight-column2 {
                padding: 0 16px;
            }

            .font-weight-column3 {
                padding: 0 25px;
            }
        }

        .text-wrap {
            height: 32px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #D1D1D1;
            display: flex;
            align-items: center;
            padding: 8px 12px;

            img {
                width: 16px;
                height: 16px;
                margin-right: 2px;
            }

            .txt {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                // margin-left: 2px;
            }

            ::v-deep .slider-box {
                width: 70px;
                margin: 0 6px;

                .el-slider__runway {
                    height: 2px;
                    margin: 2px 0;
                }

                .el-slider__bar {
                    height: 2px;
                }

                .el-slider__button-wrapper {
                    height: 32px;
                }

                .el-slider__button {
                    width: 6px;
                    height: 6px;
                    border: 1px solid $blueColor1;
                }
            }
        }
    }

    .flex-box {
        display: flex;
    }

    .flex1 {
        flex: 1;
    }

    .ml10 {
        margin-left: 10px;
    }

    ::v-deep .el-input-group__append,
    ::v-deep .el-input-group__prepend {
        background-color: #fff;
        color: #7E7E7E;
        padding: 0;
        width: 28px;
        text-align: center;
        img {
            width: 16px;
            height: 16px;
            margin: 0 auto;
        }
    }

    
    .el-input-group{
        width: 94px;
    }
    .mt20{
        margin-top: 20px;
    }

}</style>