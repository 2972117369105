<template>
    <div>
        <div class="left-box">
            <div class="cover-box">
                <img :src="currentInfo.cover_url" alt="" v-if="!cutFlag">
                <vueCropper ref="cropper" v-else :img="option.img" :outputSize="option.outputSize"
                    :outputType="option.outputType" :info="option.info" :canScale="option.canScale" :autoCrop="option.autoCrop"
                    :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight" :fixed="option.fixed"
                    :fixedBox="option.fixedBox" :fixedNumber="option.fixedNumber"></vueCropper>
            </div>
            <div class="cut-btn" v-if="cutFlag">
                <div class="el-icon-check" @click="cutSure"></div>
                <div class="mt10 el-icon-close" @click="cutClose"></div>
            </div>
            <div class="toolbar-box">
                <div class="toolbar-box-left">
                    <div class="tool-item" :class="{active: list.length}">
                        <img class="icon" src="@/assets/images/creation/creationMng_(43).png" alt="">
                        <div class="txt">撤销</div>
                    </div>
                    <div class="tool-item" :class="{active: list.length}">
                        <img class="icon" src="@/assets/images/creation/creationMng_(41).png" alt="">
                        <div class="txt">重置</div>
                    </div>
                    <div class="tool-item active">
                        <img class="icon" src="@/assets/images/creation/creationMng_(39).png" alt="">
                        <div class="txt">视频帧</div>
                    </div>
                    <div class="tool-item active" @click="cutFlag = true">
                        <img class="icon" src="@/assets/images/creation/creationMng_(38).png" alt="">
                        <div class="txt">裁剪</div>
                    </div>
                </div>
                <div class="tool-line"></div>
                <div class="toolbar-box-right">
                    <div class="tool-item active">
                        <img class="icon" src="@/assets/images/creation/creationMng_(44).png" alt="">
                        <div class="txt">停留时长</div>
                    </div>
                    <div class="slider-box">
                        <el-slider v-model="currentInfo.num" :step="0.1" :min="0.1" :max="2" :show-tooltip="false"></el-slider>
                    </div>
                    <div class="num">{{ currentInfo.num }}秒</div>
                </div>
            </div>
        </div>
        <div class="mask-all" v-if="cutFlag"></div>
    </div>
</template>
<script>
import { VueCropper } from 'vue-cropper'

export default {
    components: {
        VueCropper,
    },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        currentInfo: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            option: {
                img: 'https://cdn.guiji.ai/vpp/vpp/2023/04/28/969067344313851904/1682650121429.jpg', // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 1, // 裁剪生成图片的质量
                outputType: 'png', // 裁剪生成图片的格式
                canScale: false, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 168.75, // 默认生成截图框宽度 16:9时=524 9:16时=168.75
                autoCropHeight: 300, // 默认生成截图框高度
                fixedBox: false, // 固定截图框大小 不允许改变
                fixed: true, // 是否开启截图框宽高固定比例
                fixedNumber: [168.75, 300], // 截图框的宽高比例
                full: false, // 是否输出原图比例的截图
                canMoveBox: true, // 截图框能否拖动
                original: true, // 上传图片按照原始比例渲染
                centerBox: false, // 截图框是否被限制在图片里面
                infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
                canMove: true,
            },
            cutFlag: false,
        }
    },
    methods: {
        // sureStatus() {
        //     // 获取截图的 blob 数据
        //     this.$refs.cropper.getCropBlob((blob) => {
        //         console.log(blob)
        //         this.dialogStatusVisible = false
        //     })
        //     // 获取截图的 base64 数据
        //     this.$refs.cropper.getCropData(data => {
        //         console.log("获取 base64 数据:", data)
        //         console.log(this.base64ToFile(data, '1.png'))
        //         //这里写上传给后端的逻辑
        //         let that = this
        //         let form = new FormData()
        //         form.append('file', that.base64ToFile(data, '1.png'))
        //         ossUpload(form).then(res => {
        //             console.log(res)
        //         })
        //     })
        // },
        //解码base64，以file上传给后端
        base64ToFile(urlData, fileName) {
            let arr = urlData.split(',');
            let mime = arr[0].match(/:(.*?);/)[1];
            let bytes = atob(arr[1]); // 解码base64
            let n = bytes.length
            let ia = new Uint8Array(n);
            while (n--) {
                ia[n] = bytes.charCodeAt(n);
            }
            console.log(mime, ia)
            return new File([ia], fileName, { type: mime });
        },
        cropperOpen() {
            this.option.img = '图片地址'
        },
        // 裁剪确定
        cutSure() {
            this.$refs.cropper.getCropData(data => {
                this.cutFlag = false
                console.log(data)
                this.option.img = data

                // this.handleDownload(data)
            })
        },
        handleDownload(url) {
            var a = document.createElement("a"); // 生成一个a元素
            var event = new MouseEvent("click"); // 创建一个单击事件
            a.download = "photo"; // 设置图片名称, 这里可以自定义，也可以获取图片名称进行修改
            a.href = url; // 将生成的URL设置为a.href属性
            a.dispatchEvent(event); // 触发a的单击事件
        },
        // 裁剪取消
        cutClose() {
            this.cutFlag = false
        },
    }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>