<template>
    <div class="layer">
        <div class="txt-list">
            <div class="item">
                <div class="left">
                    <img src="@/assets/images/creation/creationMng_(13).png" alt="">
                    <div class="txt">双击输入文本111111111</div>
                </div>
                <div class="right">
                    <!-- <img src="@/assets/images/creation/creationMng_(16).png" alt=""> -->
                    <img src="@/assets/images/creation/creationMng_(15).png" alt="">
                    <img src="@/assets/images/creation/creationMng_(14).png" alt="">
                </div>
            </div>
        </div>
        <div class="img-item">
            <div class="left">
                <img src="" alt="">
                <div class="txt">背景</div>
            </div>
            <div class="right">
                <img src="@/assets/images/creation/creationMng_(16).png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    }
}
</script>
<style lang="scss" scoped>
.layer {
    width: 198px;
    height: 354px;
    background: #FFFFFF;
    border-radius: 4px;
    margin-top: 10px;
    padding: 5px;
}

::-webkit-scrollbar {
    height: 8px !important;
    width: 4px !important;
}

::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border: none;
    border-radius: 25px !important;
    height: 12px !important;
}

::-webkit-scrollbar-track-piece {
    background-color: #ededed;
    cursor: pointer;
    border-radius: 25px;
}

.img-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 188px;
    height: 33px;
    background: #FFF;
    border-radius: 4px;
    padding: 0 6px;

    .left {
        display: flex;

        img {
            width: 28px;
            height: 16px;
            object-fit: cover;
            background: #ddd;
        }

        .txt {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-left: 6px;
        }
    }

    .right {
        img {
            width: 12px;
            height: 12px;
        }
    }

    &:hover {
        background: #F7F7F7;
        // cursor: pointer;
    }
}

.txt-list {
    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 188px;
        height: 33px;
        background: #FFF;
        border-radius: 4px;
        padding: 0 6px;

        .left {
            display: flex;

            img {
                width: 14px;
                height: 14px;
                object-fit: cover;
            }

            .txt {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                margin-left: 6px;
                overflow: hidden; //超出的文本隐藏
                text-overflow: ellipsis; //溢出用省略号显示
                display: -webkit-box;
                -webkit-line-clamp: 1; // 超出多少行
                -webkit-box-orient: vertical;
                width: 100px;
            }
        }

        .right {
            display: flex;

            img {
                width: 12px;
                height: 12px;
                margin-left: 6px;
            }
        }

        &:hover {
            background: #F7F7F7;
            // cursor: pointer;
        }
    }
}
</style>