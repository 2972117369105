<template>
    <div class="posterSet">
        <el-dialog class="zc_dialog_box" :visible.sync="dialogVisible" width="935px" :close-on-click-modal='false'
            append-to-body>
            <div slot="title">
                <div class="name">设置封面</div>
            </div>
            <div class="mainSet">
                <left-content :list="list" :currentInfo="currentInfo"></left-content>
                <right-content></right-content>
            </div>
            <div slot="footer">
                <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取消</el-button>
                <el-button class="btnBgColor_blue" size="small" :loading='btnLoading' type="primary"
                    @click="affirmBtn">设置封面</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
// 1、保存每一步操作[],用来实现撤销和重置
// 2、视频帧：安装"wavesurfer.js"插件，默认第一帧
// 3、裁剪：安装vue-cropper插件，
// 4、文字：draggable拖动、放大、缩小、旋转、删除
import LeftContent from './left.vue'
import RightContent from './right.vue'
export default {
    components: {
        LeftContent,
        RightContent,
    },
    data() {
        return {
            dialogVisible: false,
            btnLoading: false,
            list: [],
            currentInfo: {
                cover_url: 'https://cdn.guiji.ai/vpp/vpp/2023/05/05/971716415608463360.png', // 封面图
                video_url: 'https://cdn.guiji.ai/video-server/mp4/1615298987059130369.mp4', // 视频
                num: 0.1, // 停留时长
                resolution: '',
                text_list: [
                    {
                        style: {
                            width: '',
                            height: '',
                            x: '',
                            y: '',
                            zIndex: '',
                            transform: 'rotateZ(0deg)', // 旋转度数
                        },
                        textStyle: {
                            scale: 1,
                            fontSize: 12,
                            color: '#333333',
                            text: '双击输入文本',
                            fontWeight: '', // 是否加粗
                            fontFamilly: '', // 字体
                            fontStyle: 'normal', // 是否倾斜 normal italic
                            textAlign: 'left', // 默认居左
                            letterSpacing: 20, // 字间距
                            lineHeight: '', // 行间距
                            textStrokeWidth: '', // 文字描边宽度-webkit-text-stroke-width
                            textStrokeColor: '', // 文字描边颜色-webkit-text-stroke-color

                        },
                    }
                ]
            }
        }
    },
    methods: {
        // 打开弹框
        openDialogBtn(item) {
            console.log(item)
            this.currentInfo.cover_url = item.video_img
            this.currentInfo.video_url = item.video_url
            this.currentInfo.resolution = item.resolution
            this.dialogVisible = !this.dialogVisible
        },
        // 取消
        cancelBtn() {
            this.openDialogBtn()
        },
        // 确定
        affirmBtn() {
            this.openDialogBtn()
        },
    }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>